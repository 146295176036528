.whatsapp-container {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 1000;
  animation: float 3s ease-in-out infinite;
}

.whatsapp-button {
  background-color: #25d366;
  color: white;
  border: none;
  padding: 15px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s;
}
.phone-float-container {
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 1000;
  animation: float 3s ease-in-out infinite;
}

.float-button {
  background-color: #256dd3;
  color: white;
  border: none;
  padding: 15px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s;
}

.whatsapp-button:hover {
  transform: scale(1.1);
}

@keyframes float {
  0%,
  100% {
    transform: translatey(0);
  }
  50% {
    transform: translatey(-10px);
  }
}

@media (max-width: 768px) {
  .whatsapp-container,
  .phone-float-container {
    bottom: 20px;
    right: 20px;
  }
  .whatsapp-button,
  .float-button {
    padding: 10px;
  }
}
