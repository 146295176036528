.darkLightMode {
  position: fixed;
  bottom: 10%;
  left: 2%;
  z-index: 9999;
}

.theme-toggle-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--theme-color);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, transform 0.3s;
}

.theme-toggle-btn:hover {
  background-color: var(--color-dark-blue);
  transform: scale(1.1);
}

.theme-toggle-btn svg {
  font-size: 1.5rem;
}
