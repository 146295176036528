body.light {
  background-color: #fff;
  color: #000;
}

.App.light {
  /* background-color: #f0f0f0; */
  color: #333;
}

body.dark {
  /* background-color: #121212; */
  color: #fff;
}

.App.dark {
  /* background-color: #333; */
  color: #ccc;
}



/* Automatically detect system theme preference */
@media (prefers-color-scheme: dark) {
  body {
    background-color: #121212;
    color: #fff;
  }
}

@media (prefers-color-scheme: light) {
  body {
    background-color: #fff;
    color: #000;
  }
}
