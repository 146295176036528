.form-section-container {
  overflow: hidden !important;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0)),
    url("../../../assets/contact.avif");
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  object-fit: cover;
  padding: 50px 0;
  color: #333;
}

.form-section-container h3 {
  color: #fff;
  font-weight: bold;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
}

.form-section-container .form-control {
  background-color: transparent;
  padding: 20px 8px;
  font-size: 18px;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 4px;
}

.form-section-container .form-control::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.form-section-container select.form-control {
  color: #ffffff;
  padding: 5px;
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 4px;
}

.form-section-container select.form-control:focus {
  border-color: #007bff;
}
.form-section-container select.form-control option {
  background-color: rgba(255, 255, 255, 0.703);
  color: #000000;
}

.form-section-container select.form-control option:hover {
  background-color: var(--theme-color);
}

.form-section-container label {
  color: #fff;
}

.dropdown-item::-webkit-scrollbar {
  display: none; 
}

@media (max-width: 768px) {
  .form-section-container .form-col-group {
    margin-top: 10px;
  }
}
