.carousel {
  position: static;
}
.carousel-inner {
  height: 700px;
}

.carousel-item img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Hero Section Container */
.herosection-coursel-container {
  position: relative;
  width: 100%;
  /* height: 100vh; */
}

/* Carousel Overlay */
.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background: #050b20a4;
  color: white;
  padding: 20px;
}

.hero-carousel-overlay-title {
  font-size: clamp(2rem, -0.3125rem + 5vw, 4rem);
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

/* Social Media Icons
.social-icons {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 10px;
}

.social-icon {
  font-size: 25px;
  color: #fff;
}
.social-icon:hover {
  color: var(--theme-color) !important;
}

*/

/* Carousel Text Styles */
h1,
h2,
h3,
h4,
h6 {
  font-family: "Arial", sans-serif;
  font-weight: bold;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: var(
    --theme-color
  ); /* Yellow color for the navigation arrows */
}

.carousel-overlay h1 {
  font-size: 100px;
  color: var(--theme-color);
  text-align: center;
}
.carousel-overlay h2,
.carousel-overlay p {
  font-family: "YourPreferredFont", sans-serif;
  font-size: 70px !important;
  color: var(--theme-color);
  font-size: 2rem;
  text-align: center;
}

.herosection-coursel-container .carousel-control-next-icon,
.herosection-coursel-container .carousel-control-prev-icon {
  display: none !important;
}
.carousel-indicators.vertical-indicators {
  position: absolute;
  top: 0;
  left: -10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.carousel-indicators.vertical-indicators li {
  width: 10px;
  height: 10px;
  margin: 15px 0;
  background-color: #ffffff;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}

.carousel-indicators.vertical-indicators .active {
  background-color: var(--color-red-orange);
  width: 12px;
  height: 12px;
}

.carousel-inner .carousel-item {
  height: 100% !important;
}

/* Social Media Icons Styling */
/* Social Media Icons Styling */
/* .social-icons {
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 15px;
  z-index: 10;
} */

/* .social-icon {
  color: white; 
  font-size: 1.5rem;
  transition: color 0.3s ease, transform 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
} */

/* Hover Effect */
/* .social-icon:hover {
  color: var(--theme-color); 
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.4);
} */

@media screen and (max-width: 768px) {
  .social-icons {
    display: none;
  }
}
