.fixed-social-box {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--theme-color);
  z-index: 9999;
  padding: 10px;
  box-shadow: var(--box-shadow);
  gap: 20px;
}

.social-icon svg {
  color: #ffffff;
  transform: scale(1);
}
.social-icon svg:hover {
  color: #007bff !important;
  cursor: pointer;
  transform: scale(1.2);
}

@media (max-width: 991px) {
  .fixed-social-box {
    display: none;
  }
}
