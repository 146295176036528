.HomeAboutUsContainer {
  display: flex;
  flex-wrap: wrap;
  /* background-color: #fff5de; */

  /* justify-content: center; */
  /* align-items: center; */
  /* text-align: center; */
  padding: 100px 0px;
}
.HomeAboutUsContainer .site-title {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 45px;
  color: var(--color-dark);
  margin-bottom: 0;
}
.HomeAboutUsContainer .site-title span {
  color: var(--main-color);
}
.HomeAboutUsContainer .about-list-wrapper {
  position: relative;
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;
}
.HomeAboutUsContainer .about-list {
  position: relative;
  display: block;
}

.HomeAboutUsContainer .list-unstyled {
  padding-left: 0;
  list-style: none;
}
.HomeAboutUsContainer .about-list li {
  position: relative;
  /* padding-left: 25px; */
  margin-top: 10px;
  font-weight: 500;
}
.HomeAboutUsContainer .about-list li .home-list-icons {
  color: var(--main-color);
  font-size: 20px;
}

.HomeAboutUsContainer .HomeAboutUsLeftContainer,
.HomeAboutUsContainer .HomeAboutUsRightContainer {
  flex: 1;
  padding: 15px;
}

/* Ensuring that the image scales properly */
.HomeAboutUsContainer .HomeAboutUsLeftContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.HomeAboutUsContainer .HomeAboutUsRightContainer {
  text-align: left;
}

/* Style for the headings */
.HomeAboutHeader {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--color-dark-blue);
}

/* Adjusting text and layout for smaller screens */
@media (max-width: 991px) {
  .HomeAboutUsContainer {
    flex-direction: column;
    text-align: center;
  }

  .HomeAboutUsContainer .HomeAboutUsRightContainer {
    margin-top: 20px;
  }
}
