.global-card {
  position: relative;
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

/* .global-card:hover .imgbx .img {
  transition: transform 0.3s ease ;
}

.global-card:hover .imgbx .img {
  transform: scale(1.1);
} */

.global-card .imgbx {
  position: relative;
  width: 100%;
  height: 240px;
}

.global-card .imgbx::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: -10px 10px 0 #fff;
}

.global-card .imgbx::after {
  content: "";
  position: absolute;
  bottom: 70px;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: -10px 10px 0 #fff;
}

.global-card .imgbx .img {
  width: 100%;
  height: 100%;
  background-size: cover;
  border-radius: 10px;
  object-fit: cover;
}

.global-content {
  position: relative;
  width: 100%;
  height: 247px;
  background: #d0f0fa;
  border-radius: 10px;
  border-top-left-radius: 0;
  
}

.global-card .global-content .price {
  position: absolute;
  height: 80px;
  width: 50%;
  background: #d0f0fa;
  top: -80px;
  border-top: 10px solid white;
  border-right: 10px solid white;
  border-top-right-radius: 25px;
}

.global-card .global-content .price::before {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  box-shadow: -10px -10px 0 #fff;
}

.global-card .global-content .price::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  bottom: 0;
  right: -25px;

  border-radius: 50%;
  box-shadow: -10px 10px 0 #d0f0fa;
}

.global-card .global-content .price p {
  position: relative;
  background: #fff;
  padding: 10px;
  margin: 15px 15px;
  display: block;
  border-radius: 7px;
  font-weight: 600;
  font-size: 17px;
}

.global-card .global-list {
  color: #18191a;
  margin-top: 20px;
  margin-bottom: 10px;
  max-height: 230px;
  list-style: none;
  font-weight: 600;
  overflow-y: auto;
}

/* Scrollbar styling */
.global-card .global-list::-webkit-scrollbar {
  width: 6px;
}

.global-card .global-list::-webkit-scrollbar-thumb {
  background-color: #236a80;
  border-radius: 5px;
}

.global-service {
  margin: 25px 0px;
  width: 70%;
  margin: auto;
}

.global-service-title {
  text-align: center;
  margin: 0px 0 40px 0;
  position: relative;
}

.global-service-title span {
  color: #03a9f4;
}

.global-service-title::after {
  content: "";
  background: #03a9f4;
  height: 2px;
  width: 60px;
  position: absolute;
  bottom: -20px;
  left: 50%;
  margin-left: -30px;
}

.global-service-content {
  font-size: clamp(20px, 4.39vw, 45px);
  text-align: center;
  font-weight: 600;
}

.global-service-content span {
  color: #03a9f4;
}

.slider-container .slick-slide > div {
  padding: 0 15px;
}

@media only screen and ((min-width : 768px) and (max-width: 996px)) {
  .global-card .global-content .price p {
    position: relative;
    background: #fff;
    padding: 10px;
    margin: 15px 15px;
    display: block;
    border-radius: 7px;
    font-weight: 600;
    font-size: 12px;
  }

  .slick-prev:before, .slick-next:before{
   display: none;
  }
}

@media only screen and ((min-width : 320px) and (max-width: 767px)) {
  .global-card .global-content .price p {
    position: relative;
    background: #fff;
    padding: 10px;
    margin: 15px 15px;
    display: block;
    border-radius: 7px;
    font-weight: 600;
    font-size: 11px;
  }

  .slick-prev:before, .slick-next:before{
    display: none;
   }
}

.slick-prev:before, .slick-next:before{
  color: #03a9f4 !important;
  padding: 5px;
}