.best-service {
  /* background-color: #f6fbff; */
  background-color: #1d3961;
  padding: 50px 0px;
  overflow: hidden;
}
.best-service .sub-title {
  font-size: 2rem;
  font-weight: bold;
  color: var(--theme-color);
}

.best-service-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.best-service-container .best-Service-left-container {
  width: 34%;
  height: auto;
}

.best-service-container .best-Service-right-container {
  width: 65%;
  height: auto;
}

.best-service-container
  .best-Service-right-container
  .service-right-container-box {
  width: 350px;
  height: auto;
  background-color: #fff;
  box-shadow: var(--box-shadow);
  margin: 20px;
  border-radius: 10px;
  padding: 30px 20px;
}

.best-service-container
  .best-Service-right-container
  .service-right-container-box
  .service-right-container-box-icon {
  width: 80px;
  height: 80px;
  border: 2px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.best-service-container
  .best-Service-right-container
  .service-right-container-box
  p {
  font-size: 14px;
  height: 150px;
  overflow-y: scroll;
}

.best-service-container
  .best-Service-right-container
  .service-right-container-box
  p::-webkit-scrollbar {
  width: 5px;
  color: rgb(139, 0, 28) !important;
}

@media (max-width: 991px) {
  .best-service-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .best-service-container .best-Service-left-container,
  .best-service-container .best-Service-right-container {
    width: 100%;
  }
  .best-service-container
    .best-Service-right-container
    .service-right-container-box {
    width: 100%;
    margin: 10px 0;
  }
}

@media (max-width: 768px) {
  .best-service-container .best-Service-left-container {
    text-align: center;
  }
  .best-service-container .best-Service-left-container .sub-title {
    font-size: 18px;
    margin-bottom: 10px;
  }

  .best-service-container
    .best-Service-right-container
    .service-right-container-box {
    padding: 20px;
  }
  .best-service-container
    .best-Service-right-container
    .service-right-container-box
    .service-right-container-box-icon {
    width: 60px;
    height: 60px;
  }
  .best-service-container
    .best-Service-right-container
    .service-right-container-box
    h3 {
    font-size: 20px;
  }
  .best-service-container
    .best-Service-right-container
    .service-right-container-box
    p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .best-service-container .best-Service-left-container {
    padding: 0 15px;
  }
  .best-service-container .best-Service-left-container .sub-title {
    font-size: 16px;
  }
  .best-service-container
    .best-Service-left-container
    .best-service-heading
    h1 {
    font-size: 20px;
  }
  .best-service-container
    .best-Service-right-container
    .service-right-container-box {
    padding: 15px;
  }
  .best-service-container
    .best-Service-right-container
    .service-right-container-box
    .service-right-container-box-icon {
    width: 50px;
    height: 50px;
  }
  .best-service-container
    .best-Service-right-container
    .service-right-container-box
    h3 {
    font-size: 18px;
  }
  .best-service-container
    .best-Service-right-container
    .service-right-container-box
    p {
    font-size: 13px;
  }
  .best-service-container
    .best-Service-right-container
    .service-right-container-box
    .service-right-container-box-icon
    img {
    width: 30px !important;
    height: 30px !important;
  }
}
