/* .footerMainContainer {
  display: flex;
  flex-direction: column;
  min-height: auto;
  background: #032040;
} */

.footer {
  margin-top: auto; /* Push footer to the bottom */
  background: #032040;
  padding-top: 45px;
  position: relative;
}
.footer .footer-about,
.footer .footer-link {
  position: relative;
  margin-bottom: 45px;
  color: #dddddd;
}

.footer .footer-url-link {
  display: flex;
  justify-content: center;
}

.footer .footer-about h3,
.footer .footer-link h3 {
  position: relative;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--theme-color);
}

.footer .footer-link a,
.footer-Link-p {
  display: block;
  margin-bottom: 10px;
  padding-left: 15px;
  font-size: 16px;
  color: #dddddd;
  cursor: pointer;
}

.footer .footer-link a:last-child {
  margin: 0;
}
.footer .footer-link a {
  text-decoration: none;
}
.footer .footer-link a:hover,
.footer-Link-p:hover {
  color: var(--theme-color);
}

.footer .footer-contact {
  position: relative;
  padding: 30px 0;
  text-align: center;
  color: #dddddd;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footer .footer-logo {
  background-color: #fff;
  position: relative;
  width: 100%;
  font-size: 50px;
  font-weight: 700;
  letter-spacing: 1px;
}

.footer .footer-logo img {
  max-width: 100%;
  max-height: 100%;
}

.footer .footer-social {
  position: relative;
}

.footer .footer-social a {
  display: inline-block;
  width: 35px;
  height: 35px;
  padding: 5px 0;
  text-align: center;
  color: #ffffff;
  font-size: 14px;
  border: 2px solid var(--theme-color);
  border-radius: 35px;
  margin: 5px;
}

.footer .footer-social a:hover {
  color: #ffffff;
  background: #0085ff;
  border-color: #0085ff;
}

.footer .footer-sub {
  position: relative;
  max-width: 400px;
  margin: 0 auto;
}

.footer .footer-sub input {
  height: 45px;
  border: none;
  border-radius: 0;
}

.footer .footer-sub .btn {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 35px;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--color-theme-color);
  background: transparent;
  border-radius: 0;
  border: 2px solid var(--color-theme-color);
  transition: 0.3s;
}

.footer .footer-sub .btn:hover {
  color: #ffffff;
  background: var(--color-theme-color);
}

.footer .active {
  color: var(--theme-color) !important;
}
.footer .footer-sub .btn:focus {
  box-shadow: none;
}

@media (max-width: 767px) {
  .footerMainContainer .footer {
    padding: 30px 15px;
  }
  .footerMainContainer .footer .footer-contact .col-md-4 {
    margin-bottom: 25px;
  }

  .footerMainContainer .footer .footer-contact .col-md-4:last-child {
    margin: 0;
  }
  .footerMainContainer .footer .copyright p,
  .footerMainContainer .footer .copyright .col-md-6:last-child p {
    margin: 5px 0;
    text-align: center;
  }
  .footer .footer-url-link {
    display: flex;
    justify-content: flex-start;
  }
}

.footerMainContainer .footer .copyright {
  position: relative;
  padding: 30px 15px;
  border-top: 1px solid rgba(256, 256, 256, 0.1);
}

.footerMainContainer .footer .copyright p {
  margin: 0;
  color: #999999;
}

.footerMainContainer .footer .copyright .col-md-6:last-child p {
  text-align: right;
}

.footerMainContainer .footer .copyright p a {
  color: #999999;
  text-decoration: underline;
}

.footerMainContainer .footer .copyright p a:hover {
  color: #0085ff;
}
