@import url("https://fonts.googleapis.com/css?family=Heebo:300,400,500,700|Roboto:300,400,500,700");

:root {
  --rob: "Roboto", sans-serif;
  --hee: "Heebo", sans-serif;
  --baseColor: #faba00;
  --dip: #222222;
  --pfont: #777777;
}

*,
html,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --main-color: #58a2d6;
  --pane-padding: 5px 42px;
  --body-font: "Roboto", sans-serif;
  --heading-font: "Inter", sans-serif;
  --theme-color: #03a9f4;
  --theme-bg-light: #f9f9f9;
  --body-text-color: #757f95;
  --color-white: #ffffff;
  --color-dark: #111111;
  --color-dark-blue: #032040;
  --color-green: #11b76b;
  --color-blue: #0049d0;
  --color-red: #e83333;
  --color-yellow: #fba707;
  --hero-overlay-color: #01060f;
  --slider-arrow-bg: rgba(255, 255, 255, 0.2);
  --box-shadow: 0 0 40px 5px rgb(0 0 0 / 5%);
  --box-shadow2: 0 0 15px rgba(0, 0, 0, 0.17);
  --transition: all 0.5s ease-in-out;
  --transition2: all 0.3s ease-in-out;
  --border-info-color: rgba(0, 0, 0, 0.08);
  --border-info-color2: rgba(0, 0, 0, 0.05);
  --border-white-color: rgba(255, 255, 255, 0.08);
  --border-white-color2: rgba(255, 255, 255, 0.05);
  --footer-bg: #111111;
  --footer-bg2: #181818;
  --footer-text-color: #f5faff;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: var(--theme-color);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(173, 218, 255);
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

a {
  transition: 0.3s;
}
a,
a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
}

.term-condition-container p {
  font-size: 18px;
}
.term-condition-container ul li {
  font-size: 18px;
}

.text-theme-color {
  color: #03a9f4;
}

.site-title {
  font-weight: 700;
  text-transform: capitalize;
  font-size: 45px;
  color: var(--color-dark);
  margin-bottom: 0;
}

.animated-text {
  font-weight: bold;
  background: linear-gradient(
    270deg,
    #ff4b1f,
    #1fddff,
    #a0ff1f,
    #ff1f7a,
    #4b1fff
  );
  background-size: 400% 400%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: colorChange 6s infinite linear;
}

@keyframes colorChange {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 100% 0%;
  }
  75% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animated-border-text {
  /* background: linear-gradient(270deg,  #1fddff,  #4b1fff); */
  animation: borderAnimation 3s infinite alternate;
}

@keyframes borderAnimation {
  0% {
    border-color: #1fddff;
  }

  100% {
    border-color: #032040;
  }
}

@keyframes boxShadowAnimation {
  0% {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 4px 20px;
  }
  50% {
    box-shadow: #ffe7ae 0px 20px 40px;
  }
  100% {
    box-shadow: rgba(17, 12, 46, 0.15) 0px 4px 20px;
  }
}

.animate-button {
  font-size: 14px;
  color: var(--color-white);
  padding: 10px 20px;
  transition: all 0.5s;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 1.5px;
  border-radius: 5px;
  font-weight: 600;
  letter-spacing: 0.5px;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  border: none;
  background: var(--theme-color);
  box-shadow: var(--box-shadow);
  z-index: 1;
}
.animate-button::before {
  content: "";
  height: 300px;
  width: 300px;
  background: var(--color-dark-blue);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  transition: transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transition: all 0.5s;
  z-index: -1;
}

.animate-button:hover::before {
  transform: translate(-50%, -50%) scale(1);
}
.animate-button:hover a {
  color: #000 !important;
  transition: all 0.3s;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}



