.theme-toggle button {
  padding: 10px 15px;
  background-color: #6adfff !important;
  border: none;
  color: #fff !important;
  font-weight: bold;
  cursor: pointer;
}

.theme-toggle button:hover {
  background-color: var(--theme-color) !important;
}

/* light mode end  */

.header_area {
  position: static;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  transition: background 0.4s, all 0.3s linear;
}
.header_area .navbar {
  background: transparent;
  padding: 0px;
  border: 0px;
  border-radius: 0px;
  z-index: 99999;
}
.header_area .navbar .navbar-brand img + img {
  display: none;
}
.navbar-brand img {
  border-radius: 50%;
}
.header_area .navbar .nav .nav-item {
  margin-right: 45px;
}
.header_area .navbar .nav .nav-item .nav-link {
  font: 500 13px/68px "Roboto", sans-serif;
  text-transform: uppercase;
  color: #000000;
  padding: 0px;
  display: inline-block;
  margin: 0px 10px;
}
.navbar-nav .nav-item.active .nav-link {
  color: var(--theme-color) !important;
  font-weight: bolder !important;
}
.navbar-nav .nav-item a {
  font-size: 16px !important;
}
.header_area .navbar .nav .nav-item .nav-link:after {
  display: none;
}
.header_area .navbar .nav .nav-item.submenu {
  position: relative;
}
.header_area .navbar .nav .nav-item.submenu ul {
  border: none;
  padding: 0px;
  border-radius: 0px;
  box-shadow: none;
  margin: 0px;
  background: #fff;
}
@media (min-width: 992px) {
  .header_area .navbar .nav .nav-item.submenu ul {
    position: absolute;
    top: 120%;
    left: 0px;
    min-width: 200px;
    text-align: left;
    opacity: 0;
    transition: all 300ms ease-in;
    visibility: hidden;
    display: block;
    border: none;
    padding: 0px;
    border-radius: 0px;
    box-shadow: 0px 10px 30px 0px rgba(255, 255, 255, 0.3);
  }
}
.header_area .navbar .nav .nav-item.submenu ul:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #eeeeee transparent transparent transparent;
  position: absolute;
  right: 24px;
  top: 45px;
  z-index: 3;
  opacity: 0;
  transition: all 400ms linear;
}
.header_area .navbar .nav .nav-item.submenu ul .nav-item {
  display: block;
  float: none;
  margin-right: 0px;
  border-bottom: 1px solid #ededed;
  margin-left: 0px;
  transition: all 0.4s linear;
}
.header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
  line-height: 45px;
  color: #222222;
  padding: 0px 30px;
  transition: all 150ms linear;
  display: block;
  margin-right: 0px;
}
.header_area .navbar .nav .nav-item.submenu ul .nav-item:last-child {
  border-bottom: none;
}
.nav-item:hover .nav-link {
  color: var(--theme-color) !important;
}
.navbar-toggler:focus {
  box-shadow: none;
}
@media (min-width: 992px) {
  .header_area .navbar .nav .nav-item.submenu:hover ul {
    visibility: visible;
    opacity: 1;
    top: 100%;
  }
}
.header_area .navbar .nav .nav-item.submenu:hover ul .nav-item {
  margin-top: 0px;
}
.header_area .navbar .nav .nav-item:last-child {
  margin-right: 0px;
}
.header_area .navbar .search {
  font-size: 12px;
  line-height: 60px;
  display: inline-block;
  color: #222222;
  margin-left: 80px;
}
.header_area .navbar .search i {
  font-weight: 600;
}
.header_area.navbar_fixed .main_menu {
  position: fixed;
  width: 100%;
  top: -70px;
  left: 0;
  right: 0;
  z-index: 99;
  background: #fff;
  transform: translateY(70px);
  transition: transform 500ms ease, background 500ms ease;
  -webkit-transition: transform 500ms ease, background 500ms ease;
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1);
}
.header_area.navbar_fixed .main_menu .navbar .navbar-brand img {
  display: none;
}
.header_area.navbar_fixed .main_menu .navbar .navbar-brand img + img {
  display: inline-block;
}
.header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
  line-height: 70px;
  color: #222222;
}

.header_socila li {
  display: inline-block;
  margin-right: 20px;
}
.header_socila li a {
  color: #fff;
  line-height: 30px;

  display: inline-block;
}
.header_socila li:last-child {
  margin-right: 0px;
}
.header_socila li:hover a {
  color: var(--theme-color);
}

.animate-button div a:hover {
  color: #fff !important;
  text-decoration: none;
}

@media (max-width: 1619px) {
  .header_area .navbar .search {
    margin-left: 40px;
  }
}

@media (max-width: 1199px) {
  .header_area .navbar .nav .nav-item {
    margin-right: 28px;
  }
}

@media (max-width: 991px) {
  .nav-phone-btn {
    display: none;
  }
  .navbar-toggler {
    border: none;
    border-radius: 0px;
    padding: 0px;
    cursor: pointer;
    margin-top: 27px;
    margin-bottom: 23px;
  }
  .header_area .navbar {
    background: #fff;
  }
  .navbar-toggler[aria-expanded="false"] span:nth-child(2) {
    opacity: 1;
  }
  .navbar-toggler[aria-expanded="true"] span:nth-child(2) {
    opacity: 0;
  }
  .navbar-toggler[aria-expanded="true"] span:first-child {
    transform: rotate(-45deg);
    position: relative;
    top: 7.5px;
  }
  .navbar-toggler[aria-expanded="true"] span:last-child {
    transform: rotate(45deg);
    bottom: 6px;
    position: relative;
  }
  .navbar-toggler span {
    display: block;
    width: 25px;
    height: 3px;
    background: #03a9f4;
    margin: auto;
    margin-bottom: 4px;
    transition: all 400ms linear;
    cursor: pointer;
  }
  .navbar .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .nav {
    padding: 0px 0px;
  }
  .header_area + section,
  .header_area + row,
  .header_area + div {
    margin-top: 117px;
  }
  .header_top .nav {
    padding: 0px;
  }
  .header_area .navbar .nav .nav-item .nav-link {
    line-height: 40px;
    margin-right: 0px;
    display: block;
    border-bottom: 1px solid #ededed33;
    border-radius: 0px;
  }
  .header_area .navbar .search {
    margin-left: 0px;
  }
  .header_area .navbar-collapse {
    max-height: 340px;
    overflow-y: scroll;
  }
  .header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
    padding: 0px 15px;
  }
  .header_area .navbar .nav .nav-item {
    margin-right: 0px;
  }
  .header_area + section,
  .header_area + row,
  .header_area + div {
    margin-top: 71px;
  }
  .home_banner_area .banner_inner .banner_content .banner_map_img {
    display: none;
  }
  .header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
    line-height: 40px;
  }
  .header_area.white_menu.navbar_fixed
    .main_menu
    .navbar
    .nav
    .nav-item
    .nav-link {
    line-height: 40px;
  }
  .header_area .navbar .nav .nav-item .nav-link {
    color: #222;
  }
}
