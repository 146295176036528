.popular_place {
  position: relative;
  overflow: hidden;
}
.popular_place .site-title {
  color: var(--theme-color);
}

.popular_place .feature_icon_1 {
  position: absolute;
  left: 22%;
  top: 42%;
  z-index: -1;
}

.popular_place .feature_icon_2 {
  position: absolute;
  right: 20%;
  top: 50%;
  z-index: -1;
}

.popular_place .feature_icon_3 {
  position: absolute;
  left: 3%;
  bottom: 70%;
  z-index: -1;
}

.popular_place .feature_icon_4 {
  position: absolute;
  right: 15%;
  top: 7%;
  z-index: -1;
}

.popular_place .single_popular_place {
  padding: 60px 25px 56px;
  text-align: center;
  height: 420px;
  border-radius: 0;
  box-shadow: 0px 3px 81px 0px rgba(216, 216, 216, 0.45);
  background-color: #ffffff;
  transition: transform 0.6s;
  border-radius: 10px;
}

.popular_place .single_popular_place img {
  max-width: 150px;
  height: 150px;
  object-fit: cover;
}

.popular_place .single_popular_place:hover {
  transform: translateY(-10px);
  background-color: #e6f9ff;
}
/* .popular_place .single_popular_place:hover img {
  background-color: red;
} */
.popular_place .single_popular_place h4 {
  font-weight: 600;
  font-size: 20px;
  margin: 45px 0 20px;
}

.popular_place .single_popular_place p {
  color: #7f7f7f;
  line-height: 1.8;
  font-size: 15px;
}

.popular_place .single_popular_place .btn1 {
  margin-top: 42px;
}

/* Media Queries */
@media (max-width: 767px) {
  /* small_mobile */
  .popular_place .single_popular_place {
    padding: 25px 10px;
    margin-top: 25px;
  }

  .popular_place .single_popular_place h4 {
    margin-bottom: 15px;
  }

  .popular_place .single_popular_place .btn1 {
    margin-top: 20px;
  }
}

@media (max-width: 991px) {
  /* large_mobile */
  .popular_place .single_popular_place {
    padding: 30px 15px;
    margin-top: 25px;
  }

  .popular_place .single_popular_place h4 {
    margin-bottom: 15px;
  }

  .popular_place .single_popular_place .btn1 {
    margin-top: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* tab_device */
  .popular_place .single_popular_place {
    padding: 30px 25px;
    margin-top: 25px;
  }

  .popular_place .single_popular_place h4 {
    margin-bottom: 15px;
  }

  .popular_place .single_popular_place .btn1 {
    margin-top: 20px;
  }
}

@media (min-width: 1200px) {
  /* medium_device */
  .popular_place .single_popular_place {
    margin-top: 25px;
  }
}
